import React from "react";
import _ from "lodash";
import Expertise from '../components/Expertise'
import Icon from '../components/Icon';
import speed from '../media/icons/speed.svg';
import IllustrationApp from '../media/illustration/Secure.png'

export default class TabPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: "app-development",
      title: "Akimed - Développement d'application pour la santé",
      description: "Akimed vous aide à développer rapidement des applications web ou mobiles certifiables pour les patients ou les professionnels de santé.",
      content:
        <div class="columns section-panel">
            <div class="column alt-column is-8">
                <div class="content is-medium">
                  <div class="tags are-medium">
                    <span class="tag">Dispositif médicaux connectés (DMC)</span>
                    <span class="tag">Digital therapeutics (DTx)</span>
                    <span class="tag">Companion apps</span>
                  </div>
                  <p>Le digital et la data ne sont plus des avantages compétitifs mais une part importante de la vie d'une entreprise ou d'un établissement publique.</p>
                  <p>Un bon usage de la technologie dans la santé permet d'améliorer la prise en charge d'un patient mais aussi d'accélérer la mise sur le marché de nouveaux médicaments.</p>
                  <p>Un partenariat avec Akimed vous offre :</p>
                  <ul>
                    <li>l'accès à une équipe avec une expérience Web, mobile et d'intégration approfondie et diversifiée</li>
                    <li>la compréhension de la conformité HIPAA, FISMA et 21 CFR Part 11</li>
                    <li>l'accès à des dizaines de cadres et de modèles permettant des temps de développement plus rapides et des coûts de projet réduits</li>
                    <li>l'utilisation de plus de 2500 cas de test automatisés.</li>
                  </ul>
                  <p>Mais surtout, un partenaire en qui vous pouvez avoir confiance et sur lequel vous pouvez compter dans votre parcours de transformation.</p>
                  <p>L’équipe d’Akimed travaille régulièrement sur des problèmes liés au sujets suivants&nbsp;:</p>
                  <ul>
                      <li>Environnement dédié ou cloud</li>
                      <li>Intégration continue</li>
                      <li>Stockage de la donnée</li>
                      <li>Orchestration</li>
                      <li>Couche de persistence</li>
                  </ul>
                </div>
            </div>
            <div class="column section-panel is-3 is-offset-1">
                <figure class="image">
                <Icon src={IllustrationApp} />
                </figure>
            </div>
        </div>
    };
  }

  render() {
    return (
        <Expertise 
          content={this.state.content} 
          activePage={this.state.page}
          title={this.state.title}
          description={this.state.description}
        />
    );
  }
}
